import styled from "styled-components";

export const DesktopContainer = styled.div`
  display: block;
  ${({ height }) => height && `height: ${height}`};
  ${({ width }) => width && `width: ${width}`};
  @media only screen and (max-width: 768px) {
    display: none;
  }
  
  /* Hide on iPads */
  @media only screen and (max-width: 1024px) and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    display: none;
  }
`;

export const PadContainer = styled.div`
  display: none;
  @media only screen and (min-width: 560px) and (max-width: 768px) {
    display: block;
  }
  
  /* Show on iPads */
  @media only screen and (max-width: 1024px) and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    display: block;
  }
`;

export const MobileContainer = styled.div`
  display: none;
  @media only screen and (max-width: 560px) {
    display: block;
  }
  
  /* Show on iPads */
  @media only screen and (max-width: 1024px) and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    display: block;
  }
`;

export const NotMobileContainer = styled.div`
  display: block;
  ${({ height }) => height && `height: ${height}`};
  ${({ width }) => width && `width: ${width}`};
  @media only screen and (max-width: 560px) {
    display: none;
  }
  
  /* Hide on iPads */
  @media only screen and (max-width: 1024px) and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    display: none;
  }
`;
