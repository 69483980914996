import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 425px;
  height: 50px;

  @media only screen and (max-width: 768px) {
    width: 314px;
    height: 32px;
  }
  
  @media only screen and (max-width: 480px) {
    width: 314px;
    height: 32px;
  }
`;

export const ContainerMobile = styled.div`
  position: relative;
  height: 50px;
  width: 72vw;
  
  @media only screen and (max-width: 480px) {
    height: 50px;
    width: 72vw;
  }
`;
