import Image from "next/image";
import { Container } from "./styled";

function Logo({ $isScrolled }) {
  return (
    <Container>
      <Image
        src={$isScrolled ? "/images/logo/classyclosets.svg" : "/images/logo/white-logo.png"}
        alt="Classy Closets Logo"
        layout="fill"
        objectFit="contain"
        loading="lazy"
        quality={100}
      />
    </Container>
  );
}

export default Logo;
