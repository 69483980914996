import Image from "next/image";
import { ContainerMobile } from "./styled";

function LogoMobile({ $isScrolled }) {
  return (
    <ContainerMobile>
      <Image
        src={$isScrolled ? "/images/logo/classyclosets.svg" : "/images/logo/white-logo.png"}
        alt="Classy Closets Logo Mobile"
        layout="fill"
        objectFit="contain"
        // width={1200}
        // height={120}
        loading="lazy"
        quality={100}
      />
    </ContainerMobile>
  );
}

export default LogoMobile;
